<template>
<div v-loading="loading">
  <div slot="header" class="modal-header popup-header">
    <span class="modal-title-popup"></span>
			<i style="float: right; padding: 3px 0" class="model-close el-icon-close icon-pointer" @click.prevent="closeForm"></i>
		</div>
    <div class="card card-custom">
        <div class="card-body p-0">
            <!--begin: Employees-->
            <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
                <!--begin: Employees Body-->
                <div class="row justify-content-center ">
                    <div class="col-xl-12">
                        <!--begin: Wizard Form-->
                         <el-form ref="form" :model="form" auto-complete="nope">
                            <!--begin: Wizard Step 1-->
                            <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                                <div class="row">
                                    <div class="col-xl-12">
                                          <el-form-item label="Customer Type" prop="customer_type">
                                            <el-select
                                              filterable
                                              clearable
                                              v-model="form.products.customer_type"
                                              placeholder="Select"
                                              style="width: 100%"
                                              @change="getCustomerTypeDiscount()"
                                            >
                                              <el-option
                                                v-for="(ct_type, index) in type_list"
                                                :key="index"
                                                :label="ct_type.type_name"
                                                :value="ct_type.id"
                                                auto-complete="nope"
                                              >
                                              </el-option>
                                            </el-select>
                                        </el-form-item>    
                                    </div>  
                                    <div class="col-xl-12">
                                          <el-form-item label="Discount %" prop="discount">
                                            <el-input-number
                                              v-model="form.products.extra_discount"
                                              placeholder="0"
                                              :min="0"
                                              :max="100"
                                              :controls="false"
                                               class = "discount"
                                               @change = "discountChange()"
                                            ></el-input-number>
                                        </el-form-item>    
                                    </div>     
                                    <div class="col-xl-12"  v-if="shiping_method !='Store Pickup' && form.products.inside_per>0">
                                          <el-form-item label="Price Type" prop="discount">
                                            <el-select
                                              filterable
                                              clearable
                                              v-model="form.products.price_type"
                                              placeholder="Select"
                                              style="width: 100%"
                                              @clear="priceTypeClear()"
                                            >
                                              <el-option
                                                v-for="(inside, index) in priceType"
                                                :key="index"
                                                :label="
                                                  inside.text +
                                                  '(' +
                                                  form.products.inside_per +
                                                  '%)'
                                                "
                                                :value="inside.id"
                                                auto-complete="off"
                                                style="height: auto; padding: 10px 20px"
                                              >
                                              </el-option>
                                            </el-select>
                                        </el-form-item>    
                                    </div>  
                                    <div class="col-xl-12 mt-5">
                                       <el-button type="primary" size="medium" style="width:100%" @click="priceChange()">Submit</el-button>
                                      </div>
                                </div>
                            </div>
                        </el-form>
                        <!--end: Employees Form-->
                    </div>
                </div>
                <!--end: Employees Body-->
            </div>
        </div>
        <!--end: Employees-->
    </div>
</div>
</template>

<script>
import Form from '@/core/services/api.form.services'
import {globalEvents} from '@/utils/globalEvents';
import {
  getCustomerType, 
} from "@/api/customer";
import {getProduct} from "@/api/product";
export default {
  name: 'create-term',
  props: {
    product: {
      type: Object,
      required: true
    },
    sel_index : {
      type: Number,
      required: true
    },
    shiping_method : {
      type : String,
      required : true
    },
    label:{
      type: Object,
      default() {
        return {button:'Create',title:'Create Term'}
      }
	  }
  },
  data() {
    return {
      loading : false,
      error:null,
      form : new Form({
        products : [],
      }),
       type_list: [],
       customer_type : [],
       priceType: [
        {
          id: 1,
          text: "Inside",
        },
      ],
    }
  },
  methods: {
    reset() {
      this.form.reset()
      this.$refs['form'].resetFields()
    },
    closeForm() {
      if (!this.form.submiting) {
        this.$emit('close')
      }
    },
    priceTypeClear() {
      this.form.products.price_type = null;
    },
    discountChange(){
      if(!this.form.products.extra_discount){
        this.form.products.extra_discount = 0
      }else{
        this.form.products.extra_discount =  this.form.products.extra_discount;
        this.form.products.price_change = 1;
      }
     
    },
    getType() {
      this.loading = true;
      getCustomerType().then((response) => {
        this.type_list = response.data.data;
        this.loading = false;
      });
    },
    getCustomerTypeDiscount(){
      this.customer_type = [];
      this.loading = true;
      let productId = this.product.product_id;
      let ProductDetails = [];
      getProduct(productId).then((response) => {
       ProductDetails.push(response.data.data)
       ProductDetails.map((prod)=>{
        if(this.form.products.customer_type == 1){
          if(prod.contractor_discount != this.form.products.extra_discount){
            this.form.products.extra_discount = prod.contractor_discount?prod.contractor_discount:0;
            this.form.products.price_change = 1;
          }
        }else  if(this.form.products.customer_type == 2){
          if(prod.dealer_discount != this.form.products.extra_discount){
            this.form.products.price_change = 1;
            this.form.products.extra_discount = prod.dealer_discount?prod.dealer_discount:0;
          }
        }else  if(this.form.products.customer_type == 4){
          if(prod.whole_discount != this.form.products.extra_discount){
            this.form.products.price_change = 1;
            this.form.products.extra_discount = prod.whole_discount?prod.whole_discount:0;
          }
        }else if(this.form.products.customer_type == 3){
          this.form.products.extra_discount = 0;
          if(this.form.products.extra_discount > 0){
            this.form.products.price_change = 1;
          }
        }
        /*this.type_list.map((custype)=>{
          if(custype.id == 1){
         this.customer_type.push(
          {
            'id' : custype.id,
            'type_name' : custype.type_name,
            'discount' : prod.contractor_discount
          }
         )
        }else if(custype.id == 2){
          this.customer_type.push(
          {
            'id' : custype.id,
            'type_name' : custype.type_name,
            'discount' : prod.dealer_discount
          }
         )
        }else if(custype.id == 4){
          this.customer_type.push(
          {
            'id' : custype.id,
            'type_name' : custype.type_name,
            'discount' : prod.whole_discount
          }
         )
        }else{
          this.customer_type.push(
          {
            'id' : custype.id,
            'type_name' : custype.type_name,
            'discount' : 0
          }
         )
          }
        })*/
       })
        this.loading = false;
      });
    },
    priceChange() {  
      globalEvents.$emit('priceChange', this.form.products,this.sel_index)
      this.$emit('close')
    }
  },
  created() {
    this.getType();
    this.form.products = this.product;
   
  },
    mounted() {
        if(this.supplier_id){
            this.form.supplier_id = this.supplier_id
        }
    }
}

</script>

<style scoped>
.option-info{
  float: left; color: #8492a6; font-size: 13px; clear:both;line-height: 20px;
}
.option-info i {
  color: #27304A;
}
.title{
  font-size: 16px;
  font-weight: 600;
  color: #27304A;
  display: inline-block;
  margin-bottom: 20px;
}
.event-container {
  background-color: white;
  padding: 20px;
  border: 1px solid #ebeef5;
}
.icon-pointer{
  font-size: 20px; cursor: pointer;color: #616e8e;
}
.discount .el-input-number{
  text-align: left !important;
}
</style>
