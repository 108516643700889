import request from '@/core/services/api.services.request'

export function getAllQuotation(params) {
  return request({
    url: '/quotation/list',
    method: 'get',
    params: params
  })
}
export function getQuotationDetails(estimate_id) {
    return request({
      url: '/quotation/view/'+estimate_id,
      method: 'get'
    })
  }
  export function downloadPdf(id,type) {
    return request({
      url: '/pdf/create/' + id +'/' + type,
      method: 'get',
      responseType: 'blob',
    })
  }
  export function deleteQuotation(estimate_id) {
    return request({
      url: '/quotation/delete/'+estimate_id,
      method: 'delete',
    })
  }